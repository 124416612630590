import '@rails/ujs';

import 'bootstrap';
import { Modal } from 'bootstrap';

import Vue from 'vue/dist/vue.esm'
import axios from 'axios'

import { cookieConsent } from '../js/utils/cookieConsent'
import 'vanilla-cookieconsent/dist/cookieconsent.css'

const maska = require('maska')
Vue.use(maska)

import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
extend('required', {
  ...required,
  message: 'Le champ est obligatoire.'
})

import FavButton from '../components/FavButton'
import RegistrationForm from '../components/RegistrationForm'

Vue.component('fav-button', FavButton)
Vue.component('registration-form', RegistrationForm)

axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['X-CSRF-Token'] = document.getElementsByName('csrf-token')[0].getAttribute('content')

document.addEventListener('DOMContentLoaded', () => {

  new Vue({
    el: '#app',
    created () {
      cookieConsent();
    },
    methods: {
      showFavModal () {
        let favModal = new Modal(document.getElementById('favModal'))
        favModal.show()
      }
    }
  })

  const editor = document.querySelector(".schedule-editor")
  const update = checkbox => {
    const inputs = checkbox.parentNode.parentNode.parentNode.querySelectorAll("input[type='time']")
    if(checkbox.checked){
      inputs.forEach(input => input.removeAttribute("disabled"))
    } else {
      inputs.forEach(input => input.setAttribute("disabled", "true"))
    }
  }
  if (editor) {
    let checkboxes = editor.querySelectorAll("input[type='checkbox']")

    checkboxes.forEach(checkbox => {
      update(checkbox)
      checkbox.addEventListener("click", () => {
        update(checkbox)
      })
    })
  }

  const rangeKind = document.querySelector('select#range_kind');
  if (rangeKind) {
    toggleRange(rangeKind.value);
    rangeKind.addEventListener('change', event => {
      toggleRange(event.target.value);
    });
  }

  const promoStartInput = document.querySelector('input#promo_starts_at');
  const promoEndInput = document.querySelector('input#promo_ends_at');

  if (promoStartInput) {
    promoStartInput.addEventListener('change', event => {
      console.log(event.target.value)
      if (promoEndInput) {
        promoEndInput.setAttribute('min', event.target.value)
      }
    })
  }

  function toggleRange (val) {
    if (val == 0) {
      toggleInput('promo_starts_at', true)
      toggleInput('promo_ends_at', true)
      toggleInput('promo_duration', false)
    } else if (val == 1) {
      toggleInput('promo_starts_at', true)
      toggleInput('promo_ends_at', false)
      toggleInput('promo_duration', true)
    } else {
      toggleInput('promo_starts_at', false)
      toggleInput('promo_ends_at', false)
      toggleInput('promo_duration', false)
    }
  };

  function toggleInput (selector, on) {
    const group = document.querySelector('.' + selector);
    const input = document.querySelector('input#' + selector);

    if (on) {
      group.classList.remove('d-none');
      input.setAttribute('required', '');
      input.required = true;
    } else {
      group.classList.add('d-none');
      input.removeAttribute('required');
      input.required = false;
    }
  }
})
