<template lang="pug">
  Component(
    :is="tag"
    v-bind="options"
    @click.prevent="toggleToFavorites"
  )
    i.fa-heart(aria-hidden="true", :class="iconClass")
    span.ms-1(v-if="lg") {{ title }} ma liste
</template>

<script>
import axios from 'axios'

export default {
  name: 'FavButton',
  props: {
    tag: {
      type: String,
      default: 'button'
    },
    storeId: {
      type: Number|String,
      required: true
    },
    lg: Boolean
  },
  data () {
    return {
      favoriteIds: []
    }
  },
  computed: {
    title () {
      return this.active ? 'Retirer de' : 'Ajouter à'
    },
    active () {
      return this.favoriteIds.includes(this.storeId)
    },
    iconClass () {
      let active = this.active ? 'far' : 'fas'
      let lg = this.lg ? 'fa-fw' : 'btn-fav__icon'
      return [active, lg]
    },
    options () {
      let opts = {
        'class': { 'active': this.active },
        'title': `${this.title} mes favoris`,
        'aria-label': `${this.title} mes favoris`,
      }
      opts['type'] = this.tag == 'button' && 'button'
      opts['role'] = this.tag == 'span' && 'button'
      return opts
    }
  },
  mounted () {
    this.getFavorites()
  },
  methods: {
    getFavorites () {
      axios.get('/stores/favorites').then(resp => {
        this.favoriteIds = resp.data
      })
    },
    toggleToFavorites () {
      axios.post(`/stores/${this.storeId}/toggle_favorite/`, {
        favorite_id: this.storeId
      }).then(resp => {
        this.favoriteIds = resp.data
      }).catch(err => {
        if (err.response && err.response.status === 401) {
          this.$emit('fav-modal')
        }
      })
    }
  }
}
</script>
