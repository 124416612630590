<template lang="pug">
  vv-obs(tag="form" class="row" v-slot="{ invalid }" @submit.prevent="submit")
    .form-max.col
      .card.rounded-3.shadow-lg
        .card-body.p-lg-5
          .row.g-3.mb-3
            .col-sm
              label.control-label.form-label(for="firstname")
                span.me-1 Prénom
                abbr(title="Champ obligatoire") *
              vv-prov(v-slot="{ errors, classes }" rules="required" name="firstname")
                input.form-control#firstname(v-model="params.first_name" placeholder="Votre / vos prénom(s)" required autofocus, :class="novalid('first_name', classes)")
                .invalid-feedback {{ errors[0] }}
            .col-sm
              label.control-label.form-label(for="lastname")
                span.me-1 Nom
                abbr(title="Champ obligatoire") *
              vv-prov(v-slot="{ errors, classes }" rules="required" name="lastname")
                input.form-control#lastname(v-model="params.last_name" placeholder="Votre / vos nom(s) de famille" required, :class="novalid('last_name', classes)")
                .invalid-feedback {{ errors[0] }}
          .mb-3
            label.control-label.form-label(for="email")
              span.me-1 E-mail
              abbr(title="Champ obligatoire") *
            vv-prov(v-slot="{ errors, classes }" rules="required" name="email")
              input.form-control#email(v-model="params.email" placeholder="Votre adresse e-mail" required aria-describedby="emailFeedback", :class="novalid('email', classes)")
              .invalid-feedback {{ errors[0] || "L'email " + errorsBack['email'] }}
          .mb-3
            label.form-label(for="phoneNumber") Votre numéro de téléphone
            input#phoneNumber.form-control(
              type="tel"
              v-model="params.phone_number"
              v-maska="'## ## ## ## ##'"
              :class="novalid('phone_number')"
              placeholder="Votre numéro de fixe ou portable")
            .invalid-feedback {{ "Le numéro de téléphone " + errorsBack['phone_number'] }}
          .mb-3
            label.control-label.form-label(for="password")
              span.me-1 Mot de passe
              abbr(title="Champ obligatoire") *
            vv-prov(v-slot="{ errors, classes }" rules="required" name="password")
              input.form-control#password(
                type="password"
                v-model="params.password"
                required
                aria-describedby="pwdFeedback"
                :class="novalid('password', classes)"
              )
              .invalid-feedback {{ errors[0] }}
              small.form-text.text-muted(v-if="emptyError('password')")
                | Le mot de passe doit contenir 8 caractères minimum, 1 majuscule, 1 minuscule, 1 chiffre et 1 symbole au minimum
              .invalid-feedback(v-else) Le mot de passe {{ errorsBack['password'] }}.
          .mb-3
            label.control-label.form-label(for="pwdConfirmation")
              span.me-1 Confirmation du mot de passe
              abbr(title="Champ obligatoire") *
            vv-prov(v-slot="{ errors, classes }" rules="required" name="pwdConfirmation")
              input.form-control#pwdConfirmation(
                type="password"
                v-model="params.password_confirmation"
                required
                aria-describedby="pwdConfirmation"
                :class="novalid('password_confirmation', classes)"
              )
              .invalid-feedback {{ errors[0] || errorsBack['password_confirmation'] }}
          .mb-3
            .form-check
              input#fav_optin.form-check-input(type="checkbox" v-model="params.fav_optin" required, :class="novalid('fav_optin')")
              label.form-check-label.form-label(for="fav_optin")
                span En m'inscrivant, j'accepte de recevoir les offres promotionnelles de mes enseignes favorites.
                abbr(title="Champ obligatoire") *
            .invalid-feedback {{ errorsBack['fav_optin'] }}
          .mb-3
            .form-check
              input#optin.form-check-input(type="checkbox" v-model="params.optin")
              label.form-check-label(for="optin") J’accepte de recevoir les offres promotionnelles du centre commerciale Portes du bassin.
          .row.align-items-center.flex-column-reverse.flex-xs-row.mt-4
            .col-xs
              p.mb-2 J'ai déjà un compte ?
              a.link--underline-reverse.text-secondary.fw-medium(href="/utilisateurs/connexion") Je me connecte
            .col-xs.mt-2.mb-5.my-xs-0
              button.btn.btn-lg.fs-base.fw-medium.btn-warning.w-100.d-flex.justify-content-center(type="submit", :disabled="invalid || loading")
                MoonLoader.me-2(:loading="loading" color="#0F1C49" size="20px")
                | Inscription
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { isEmpty } from 'lodash'
import axios from 'axios'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'

export default {
  name: 'RegistrationForm',
  components: {
    MoonLoader,
    'vv-obs': ValidationObserver,
    'vv-prov': ValidationProvider
  },
  props: {
    src: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      params: {
        terms: false
      },
      errorsBack: {}
    }
  },
  methods: {
    novalid (attribute, classes = {}) {
      return { 'is-invalid': classes.invalid || this.errorsBack[attribute] }
    },
    emptyError (attribute) {
      return isEmpty(this.errorsBack[attribute])
    },
    submit () {
      this.loading = true
      axios.post(this.src, {
        user: this.params
      }).then(resp => {
        window.location = resp.headers.location
        this.loading = false
      }).catch(err => {
        this.errorsBack = {}
        if (err.response.status === 422) {
          this.errorsBack = err.response.data
        }
        this.loading = false
      })
    }
  },
  watch: {
    params: {
      handler (obj) {
        ['email', 'phone_number', 'password', 'password_confirmation', 'fav_optin'].forEach(attribute => {
          if (isEmpty(obj[attribute])) {
            delete this.errorsBack[attribute]
          }
        })
      },
      deep: true
    }
  }
}
</script>
