const CookieConsent = require('vanilla-cookieconsent/dist/cookieconsent.js')

export const cookieConsent = () => {
  const consent = initCookieConsent()

  consent.run({
    current_lang: 'fr',
    page_scripts: true,
    gui_options: {
      consent_modal : {
        transition: 'slide'
      }
    },
    languages: {
      fr: {
        consent_modal: {
          title: 'Nous utilisons des cookies !',
          description: `
            <p>Nous utilisons des cookies afin de collecter des informations à des fins diverses, notamment :</p>
            <ol class="ms-4 mt-1">
              <li>Fonctionnelles</li>
              <li>Statistiques</li>
            </ol>
            <p class="mt-2">
              En cliquant sur « Accepter », vous donnez votre consentement à toutes les fins énoncées.
              Vous pouvez également choisir de spécifier les finalités en cliquant sur « Préférences ».
            </p>
            <p class="mt-2">
              <a href="/politique-de-confidentialite" class="text-primary">En savoir plus sur les cookies</a>
            </p>
          `,
          primary_btn: {
            text: 'Accepter',
            role: 'accept_all'
          },
          secondary_btn: {
            text: 'Préférences',
            role: 'settings'
          }
        },
        settings_modal: {
          title: 'Préférences cookies',
          save_settings_btn: 'Sauvegarder',
          accept_all_btn: 'Tout accepter',
          reject_all_btn: 'Tout refuser',
          close_btn_label: 'Fermer',
          blocks: [
            {
              title: 'Cookies strictement nécessaires',
              description: `
                Ces cookies nous permettent de garantir la fluidité et la sécurité du service.
                Ils sont exemptés de consentement conformément aux exceptions prévues à l’article 82 de la loi informatique et libertés.
              `,
              toggle: {
                value: 'necessary',
                enabled: true,
                readonly: true
              }
            },
            {
              title: 'Google Analytics',
              description: 'Ces cookies nous permettent d\'analyser les statistiques de consultation de notre site. Vos sessions sont anonymisées.',
              toggle: {
                value: 'analytics',
                enabled: false,
                readonly: false
              }
            }
          ]
        }
      }
    }
  })
}
